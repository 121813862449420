@if (visible) {
  <div class="container mb">
    <b>❤️ Thank you for providing feedback in the Sky Planner survey!</b><br/>
    With a staggering 400 responses, way more than I expected, there's a lot of data to go through. I'll do my best to make sure your feedback is heard and considered.<br/>
    Please keep in mind that the Sky Planner is made in my spare time and keeping the site up-to-date with Sky always has priority.<br/>
    I will have to prioritize what I work on and it can take a while before you'll notice some of the changes you've been waiting for 🙇‍♂️<br/>
    If you want to get in touch, you can always reach out to me on Discord: Silverfeelin<br/>
    <div class="sky-flex flex-wrap gap-half mt-half">
      <button (click)="close()">
        <mat-icon class="menu-icon">close</mat-icon>
        <span class="menu-label">Dismiss</span>
      </button>
    </div>
  </div>
}
