<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Realms</h1>
  </div>
  <div class="sky-card-body">
    <app-wiki-link [wiki]="{href: 'https://sky-children-of-the-light.fandom.com/wiki/Realms'}"></app-wiki-link>
  </div>
</div>

<!-- Map -->
<app-card class="mt" [foldable]="true" [folded]="!showMap" [title]="'Map'" (beforeFold)="beforeFoldMap($event)">
  <div class="map-container" #mapContainer>
    <div class="map-controls map-controls-tl">
      <button type="button" [class.map-control-off]="!showAreas" [ngbTooltip]="'Show areas'" container="body" placement="bottom-start" (click)="toggleShowAreas()">
        <mat-icon>location_on</mat-icon>
      </button>
      <button type="button" [class.map-control-off]="!showMapShrines" [ngbTooltip]="'Show map shrines'" container="body" placement="bottom-start" (click)="toggleShowMapShrines()">
        <mat-icon [svgIcon]="'map-shrine'"></mat-icon>
      </button>
      <button type="button" [class.map-control-off]="!showWingedLight" [ngbTooltip]="'Show winged light'" container="body" placement="bottom-start" (click)="toggleShowWingedLight()">
        <mat-icon [svgIcon]="'flaps'"></mat-icon>
      </button>
    </div>
    <div class="map"></div>
  </div>
</app-card>

<div class="grid grid-3 mt">
  <div class="sky-card p-rel" *ngFor="let realm of visibleRealms">
    <div class="sky-card-header">
      <h2 class="h3 mb-0">{{realm.name}}</h2>
    </div>
    <div class="sky-card-body">
      <!-- Image -->
      <div *ngIf="realm.imageUrl" class="p-rel img sky-border-solid"
        [style.background-image]="'url('+realm.imageUrl+')'"
        [style.background-position]="realm.imagePosition || undefined">
      </div>
      <!-- Overview -->
      <a class="item link mt" [routerLink]="['/realm', realm.guid]">
        <mat-icon class="menu-icon">article</mat-icon>
        <span class="menu-label">Overview</span>
      </a>
      <!-- Areas -->
      @if (realm.areas?.length) {
        <a class="item link mt" [routerLink]="['/area']" [queryParams]="{ realm: realm.guid }">
          <mat-icon class="menu-icon">location_on</mat-icon>
          <span class="menu-label">Areas</span>
        </a>
      }
    </div>
  </div>
</div>
