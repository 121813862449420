<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Traveling Spirits</h1>
  </div>
  <div class="sky-card-body">
    <div class="grid grid-2">
      <app-wiki-link [wiki]="{href: 'https://sky-children-of-the-light.fandom.com/wiki/Traveling_Spirits'}"></app-wiki-link>
      <app-calendar-link [cal]="{href: 'https://skydreamers.notion.site/Sky-Event-Calendar-FM-ec6e6134924048859b2a8410b0a8b20d'}"></app-calendar-link>
    </div>
  </div>
</div>

<div class="sky-card p-0 mt">
  <div class="sky-card-body">
    <app-table [rows]="rows">
      <ng-template [appTableHeader] [fit]="true">#</ng-template>
      <ng-template [appTableHeader] [fit]="true" [textAlign]="'center'"></ng-template>
      <ng-template [appTableHeader]>Spirit</ng-template>
      <ng-template [appTableHeader]>Date</ng-template>
      <ng-template [appTableHeader]>Visit</ng-template>
      <ng-template [appTableHeader]>Unlocked</ng-template>
      <ng-template [appTableColumn] let-row="row">
        {{ row.number }}
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <div class="sky-card spirit-img t-center">
          <app-icon *ngIf="row.spirit.imageUrl" [src]="row.spirit.imageUrl" [width]="'auto'" [lazy]="true"></app-icon>
        </div>
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <a [routerLink]="['/spirit', row.spiritGuid]" [queryParams]="{ highlightTree: row.treeGuid }">
          {{ row.spirit.name }}
        </a>
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <span class="ws-nw"><app-date [date]="row.date"></app-date></span>
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        {{ row.visit }}
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <span *ngIf="row.totalItems" [class.completed]="row.unlockedItems === row.totalItems">
          {{ row.unlockedItems }} / {{ row.totalItems }}
        </span>
      </ng-template>
    </app-table>
  </div>
</div>
