<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">
      Spirits
      <a (click)="changeMode()" class="h6 link">Change view</a>
    </h1>
  </div>
  <div class="sky-card-body">
    <div class="sky-flex flex-wrap">
      <button type="button" (click)="sortDefault()">Default</button>
      <button type="button" (click)="sortName()">Alphabetical</button>
      <button type="button" (click)="sortAge(1)">Oldest first</button>
      <button type="button" (click)="sortAge(-1)">Newest first</button>
    </div>
  </div>
</div>

<!-- #region List -->
<div class="sky-card mt p-0" *ngIf="mode === 'grid'">
  <div class="sky-card-body scroll-x">
    <app-table [rows]="rows">
      <!-- Headers-->
      <ng-template [appTableHeader]>Spirit</ng-template>
      <ng-template [appTableHeader]>Type</ng-template>
      <ng-template [appTableHeader]>Location</ng-template>
      <ng-template [appTableHeader]>Unlocked</ng-template>
      <!-- Body -->
      <!-- Name -->
      <ng-template [appTableColumn] let-row="row">
        <a [routerLink]="['/spirit', row.guid]">{{ row.name }}</a>
      </ng-template>
      <!-- Image -->
      <!-- <ng-template [appTableColumn] let-row="row">
        <img *ngIf="row.imageUrl" [src]="row.imageUrl" [alt]="row.name" />
      </ng-template> -->
      <!-- Type -->
      <ng-template [appTableColumn] let-row="row">
        <app-spirit-type-icon [type]="row.type"></app-spirit-type-icon>
      </ng-template>
      <!-- Location -->
      <ng-template [appTableColumn] let-row="row">
        <a [routerLink]="row.areaGuid ? ['/area', row.areaGuid] : undefined">
          {{ row.area?.name }}
        </a>
      </ng-template>
      <!-- Progress -->
      <ng-template [appTableColumn] let-row="row">
        <span *ngIf="row.totalItems"
          [class.partial]="(row.unlockedLast && row.unlockedLast === row.totalLast) || ((row.unlockedFree || row.totalPass) && row.unlockedFree === row.totalFree)"
          [class.completed]="row.unlockedItems === row.totalItems"
          [ngbTooltip]="row.unlockTooltip" container="body" placement="left"
        >
          {{ row.unlockedItems }} / {{ row.totalItems }}
        </span>
      </ng-template>
      <!-- Footers -->
      <ng-template [appTableFooter] [colspan]="3" [textAlign]="'right'">Total:</ng-template>
      <ng-template [appTableFooter]>
        <span [class.completed]="unlockedItems === totalItems">{{ unlockedItems }} / {{ totalItems }}</span>
      </ng-template>
    </app-table>
  </div>
</div>
<!-- #endregion-->

<!-- #region Cards -->
<div class="grid grid-4 mt" *ngIf="mode === 'cards'">
  <div class="sky-card p-rel" *ngFor="let row of rows">
    <div class="sky-card-header">
      <h2 class="h3 mb-0">
        <app-spirit-type-icon [type]="row.type"></app-spirit-type-icon>
        {{row.name}}
      </h2>
    </div>
    <div class="sky-card-body">
      <!-- Image -->
      <div *ngIf="row.imageUrl" class="p-rel item spirit-img">
        <img [src]="row.imageUrl" loading="lazy">
      </div>
      <!-- Info -->
      <a class="mt item link" [routerLink]="['/spirit', row.guid]">
        <mat-icon class="menu-icon">list</mat-icon>
        <span class="menu-label">Information</span>
      </a>
      <!-- Progress -->
      <div class="mt p-rel item">
        <mat-icon class="menu-icon">lock_open</mat-icon>
        <div class="d-inline-block menu-label">
          <span *ngIf="row.totalItems"
          [class.partial]="(row.unlockedLast && row.unlockedLast === row.totalLast) || ((row.unlockedFree || row.totalPass) && row.unlockedFree === row.totalFree)"
          [class.completed]="row.unlockedItems === row.totalItems"
          [ngbTooltip]="row.unlockTooltip" container="body" placement="bottom"
          >
            {{ row.unlockedItems }} / {{ row.totalItems }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- #endregion -->

<!-- Templates -->

<!-- Type -->
<ng-template #type let-row="row">
  <ng-container [ngSwitch]="row.type">
    <mat-icon *ngSwitchCase="'Elder'" [svgIcon]="'ascended-candle'"
      [ngbTooltip]="'Elder Spirit'" container="body" placement="bottom">
    </mat-icon>
    <mat-icon *ngSwitchCase="'Guide'" [svgIcon]="'season-heart'"
      [ngbTooltip]="'Season Guide'" container="body" placement="bottom">
    </mat-icon>
    <mat-icon *ngSwitchCase="'Regular'" [svgIcon]="'candle'"
      [ngbTooltip]="'Regular Spirit'" container="body" placement="bottom">
    </mat-icon>
    <mat-icon *ngSwitchCase="'Season'" [svgIcon]="'season-candle'"
      [ngbTooltip]="'Season Spirit'" container="body" placement="bottom">
    </mat-icon>
    <span *ngSwitchDefault>{{ row.type }}</span>
  </ng-container>
</ng-template>
