<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">{{ season.name }} <span class="c-accent">(#{{season.number}})</span></h1>
  </div>
  <div class="sky-card-body">
    <div class="grid grid-2">
      <!-- Wiki -->
      <app-wiki-link [wiki]="season._wiki" [aClass]="'container' + (!season._calendar ? ' colspan-2' : '')"></app-wiki-link>
      <!-- Calendar -->
      <app-calendar-link [cal]="season._calendar" [aClass]="'container'"></app-calendar-link>
      <!-- Prev next -->
      <a class="container" *ngIf="previousSeason" [routerLink]="['/season', previousSeason.guid]" [class.colspan-2]="!nextSeason">
        <mat-icon class="menu-icon">arrow_back</mat-icon>
        <span class="menu-label">
          Previous season
        </span>
      </a>
      <a class="container" *ngIf="nextSeason" [routerLink]="['/season', nextSeason.guid]"  [class.colspan-2]="!previousSeason">
        <mat-icon class="menu-icon">arrow_forward</mat-icon>
        <span class="menu-label">
          Next season
        </span>
      </a>
      <!-- Date -->
      <div class="container" [class.expired]="state === 'ended'">
        <mat-icon class="menu-icon">calendar_month</mat-icon>
        <span class="menu-label">
          <app-date [date]="season.date"></app-date>
          to
          <app-date [date]="season.endDate"></app-date>
          <span class="c-accent" *ngIf="state !== 'active'">
            (<app-days-left [start]="season.date" [end]="season.endDate"></app-days-left>)
          </span>
        </span>
      </div>
      <!-- Duration -->
      <div class="container" [class.expired]="state === 'ended'">
        <mat-icon class="menu-icon">schedule</mat-icon>
        <span class="menu-label">
          <app-duration [start]="season.date" [end]="season.endDate"></app-duration>
          <span class="c-accent" *ngIf="state === 'active'">
            (<app-days-left [start]="season.date" [end]="season.endDate"></app-days-left>)
          </span>
        </span>
      </div>
      <!-- Candles -->
      <div class="container">
        <mat-icon class="menu-icon" svgIcon="season-candle"></mat-icon>
        <span class="menu-label">
          {{ sc }} season candles
          <span class="c-accent" *ngIf="scLeft">({{ scLeft }} needed)</span>
        </span>
      </div>
      <!-- Hearts -->
      <div class="container">
        <mat-icon class="menu-icon" svgIcon="season-heart"></mat-icon>
        <span class="menu-label">
          {{ sh }} season hearts
          <span class="c-accent" *ngIf="shLeft">({{ shLeft }} needed)</span>
        </span>
      </div>
      <!-- Spirits -->
      <a class="container colspan-2" [routerLink]="'/spirit'" [queryParams]="{ season: season.guid, type: 'Season,Guide' }">
        <mat-icon class="menu-icon">list</mat-icon>
        <span class="menu-label">
          Spirits
          <span class="c-accent">({{ season.spirits.length - 1 }})</span>
        </span>
      </a>
      <!-- Season Pass -->
      <div  class="container colspan-2">
        <div class="sky-flex flex-wrap">
          <div class="btn-unlock action d-inline-block" (click)="toggleSeasonPass(false)">
            <mat-icon class="menu-icon">{{ hasBoughtSeasonPass ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
            <span class="menu-label">
              Bought Season Pass
            </span>
          </div>
          <div class="btn-unlock action d-inline-block" (click)="toggleSeasonPass(true)">
            <mat-icon class="menu-icon">{{ hasGiftedSeasonPass ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
            <span class="menu-label">
              Received Season Pass
            </span>
          </div>
        </div>
      </div>
      <!-- Calculator -->
      @if (state === 'active' || state === 'future') {
        <a class="container colspan-2" [routerLink]="'/season-calculator'">
          <mat-icon class="menu-icon">calculate</mat-icon>
          <span class="menu-label">
            Season Calculator
          </span>
        </a>
      }
      <!-- Draft -->
      <div class="container colspan-2" *ngIf="season.draft">
        <mat-icon class="menu-icon">warning</mat-icon>
        <span class="menu-label">
          This data has not yet been checked on the live version of Sky. Data may be incomplete or inaccurate.
        </span>
      </div>
    </div>
  </div>
</div>

@if (state === 'ended' && guide && guideTreePostSeason) {
  <div class="mt">
    <app-card [title]="'Post-season guide tree'" [foldable]="true" [folded]="true">
      <div class="container">
        The current spirit tree of the season guide is displayed here. Revisions can be found on the spirit page.
      </div>
      <div class="tree-wrapper mt">
        <app-spirit-tree [tree]="guideTreePostSeason" [name]="guide!.name" [highlight]="highlightTree === guideTreePostSeason.guid">
          <!-- Link to spirit -->
          <div name>
            <a [routerLink]="['/spirit', guide.guid]" [queryParams]="{ highlightTree: guideTreePostSeason.guid }">
              {{ guide.name }}
            </a>
          </div>
        </app-spirit-tree>
      </div>
    </app-card>
  </div>
}
<!-- Spirits -->
<div class="mt">
  <app-card [title]="state === 'ended' ? 'Season spirit trees' : 'Spirit trees'" [foldable]="true" [folded]="false">
    <div class="sky-flex scroll-x">
      <!-- Guide tree -->
      @if (guide && guideTree) {
        <div class="tree-wrapper">
          <app-spirit-tree [tree]="guideTree" [name]="guide!.name" [highlight]="highlightTree === guideTree.guid">
              <!-- Link to spirit -->
              <div name>
                <a [routerLink]="['/spirit', guide.guid]" [queryParams]="{ highlightTree: guideTree.guid }">
                  {{ guide.name }}
                </a>
              </div>
          </app-spirit-tree>
        </div>
      }
      <!-- Traveling Spirit trees -->
      @if (spirits) {
        @for (spirit of spirits; track spirit.guid) {
          <div class="tree-wrapper">
            <app-spirit-tree [tree]="spiritTrees[spirit.guid]" [name]="spirit.name" [highlight]="highlightTree === spiritTrees[spirit.guid].guid">
              <!-- Link to spirit -->
              <div name>
                <a [routerLink]="['/spirit', spirit.guid]" [queryParams]="{ highlightTree: spiritTrees[spirit.guid].guid }">
                  {{ spirit.name }}
                </a>
              </div>
            </app-spirit-tree>
          </div>
        }
      }
    </div>
  </app-card>
</div>

<!-- Extra spirit trees included on the season page. -->
@if (includedTrees?.length) {
  <div class="mt">
    <app-card [title]="'Related spirit trees'" [foldable]="true" [folded]="false">
      <div class="sky-flex scroll-x">
        @for (tree of includedTrees; track tree.guid) {
          <div class="tree-wrapper">
            <app-spirit-tree [tree]="tree" [name]="tree.name ?? tree.spirit?.name ?? 'Spirit tree'">
              @if (tree.spirit) {
                <!-- Link to spirit -->
                <div name>
                  <a [routerLink]="['/spirit', tree.spirit.guid]">
                    {{ tree.spirit.name }}
                  </a>
                </div>
              }
            </app-spirit-tree>
          </div>
        }
      </div>
    </app-card>
  </div>
}

<!-- Shops -->
@if (shops.length) {
  <div class="sky-card mt">
    <div class="sky-card-header">
      <h1 class="h2 mb-0">Shops</h1>
    </div>
    <div class="sky-card-body">
      @for (shop of shops; track shop.guid) {
        <app-item-list [itemList]="shop.itemList!"></app-item-list>
      }
    </div>
  </div>
}

<!-- IAPs -->
@if (iapShops.length) {
  <div class="sky-card mt">
    <div class="sky-card-header">
      <h2 class="h3">In-App Purchases</h2>
    </div>
    <div class="sky-card-body">
      <div class="grid grid-4" >
        <!-- Render IAPs in order of sorted shops. -->
        @for (shop of iapShops; track shop.guid) {
          @for (iap of shop.iaps; track iap.guid) {
            <app-iap-card [iap]="iap" [highlightIap]="highlightIap"></app-iap-card>
          }
        }
      </div>
    </div>
  </div>
}
