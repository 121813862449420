<div class="icon-picker" (keydown)="pickerKeydown($event)">
  <div class="picker-grid">
    <ng-container *ngFor="let result of searchResults; let i = index;">
      <app-item-icon class="point" [item]="result.data" [opaque]="true" [subIcons]="[]" [hoverGlow]="false" (click)="select(result.data)" ></app-item-icon>
    </ng-container>
  </div>
  <div class="picker-close" (click)="close($event)">
    <mat-icon>close</mat-icon>
  </div>
  <div class="search">
    <input tabindex="0" #input class="search-input" type="text" (input)="search()" placeholder="Search for icons">
    <mat-icon (click)="selectSearch()">search</mat-icon>
  </div>
</div>
