<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Events</h1>
  </div>
  <div class="sky-card-body">
    <div class="grid grid-2">
      <app-wiki-link [wiki]="{href: 'https://sky-children-of-the-light.fandom.com/wiki/Special_Events'}"></app-wiki-link>
      <app-calendar-link [cal]="{href: 'https://skydreamers.notion.site/Sky-Event-Calendar-FM-ec6e6134924048859b2a8410b0a8b20d'}"></app-calendar-link>
      <a class="container" [routerLink]="'/event/history'">
        <mat-icon class="menu-icon">history</mat-icon>
        <span class="menu-label">History</span>
      </a>
      <a class="container" [routerLink]="'/event-calculator'">
        <mat-icon class="menu-icon">calculate</mat-icon>
        <span class="menu-label">Event Calculator</span>
      </a>
    </div>
  </div>
</div>

<!-- Recurring -->
<div class="sky-card mt">
  <div class="sky-card-header">
    <h2 class="h2">Recurring events</h2>
  </div>
</div>
<div class="grid grid-4 mt">
  <app-event-card [event]="event" *ngFor="let event of recurring; let i = index;" [options]="{show: ['img', 'list', 'recent']}"></app-event-card>
</div>

<!-- Other -->
<div class="sky-card mt">
  <div class="sky-card-header">
    <h2 class="h2">Other events</h2>
  </div>
</div>
<div class="grid grid-4 mt">
  <app-event-card [event]="event" *ngFor="let event of old; let i = index;" [options]="{show: ['img', 'list', 'upcoming', 'recent' ]}"></app-event-card>
</div>
