<div class="clock">
  <div class="clock-item">
    <div class="container">
      <div class="sky-clock">
        <div class="hand-hour hand" [style.transform]="'rotate('+localDegHr+'deg)'"></div>
        <div class="hand-minute hand" [style.transform]="'rotate('+localDegMin+'deg)'"></div>
        <div class="hand-second hand" [style.transform]="'rotate('+localDegSec+'deg)'"></div>

        <div class="hand-inner"></div>
      </div>
      <div class="clock-text">
        <span class="clock-title">Local time</span><br/>
        <span class="clock-time" *ngIf="nowLocal"><app-date [date]="nowLocal" [format]="'HH:mm:ss'"></app-date></span>
      </div>
    </div>
  </div>
  <div class="clock-item">
    <div class="container">
      <div class="sky-clock">
        <div class="hand-hour hand" [style.transform]="'rotate('+skyDegHr+'deg)'"></div>
        <div class="hand-minute hand" [style.transform]="'rotate('+skyDegMin+'deg)'"></div>
        <div class="hand-second hand" [style.transform]="'rotate('+skyDegSec+'deg)'"></div>
        <div class="hand-inner"></div>
      </div>
      <div class="clock-text">
        <span class="clock-title">Sky time</span>
        @if (shardHasLanded || shardWillLand) {
          &nbsp;(
            <div class="d-inline-block p-rel v-top">
              <img
                [src]="shardType === 'red' ? '/assets/external/wiki-shard-red.webp' : '/assets/external/wiki-shard-black.webp'"
                class="clock-shard clock-shard-red point" [class.clock-shard-active]="shardHasLanded" (click)="gotoShard()"
                [ngbTooltip]="'Visit Sky Shards'" placement="bottom" container="body"
              />
              @if (!shardHasLanded && shardWillLand) {
                <mat-icon class="clock-shard-future">update</mat-icon>
              }
            </div>
          )
        }
        <br/>
        <span class="clock-time" *ngIf="nowSky"><app-date [date]="nowSky" [format]="'HH:mm:ss'"></app-date></span>
      </div>
    </div>
  </div>
</div>
